// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore smaller bundle for react-player
import ReactPlayer from "react-player/file"
import styled from "styled-components"
import { COLORS, FONTS, MOBILE_MAX_WIDTH } from "../../../styles"
import Divider from "../../../images/holiday-divider.svg"
import HolidayStyles from "../page.styles"
import Img from "gatsby-image"

export default {
  Container: styled.div`
    position: relative;
    height: 500px;
    width: 100%;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Video: styled(ReactPlayer)`
    position: absolute;
    video {
      object-fit: cover;
    }
  `,
  ImageWrapper: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
  `,
  Image: styled(Img)`
    width: 100%;
    height: 100%;
  `,
  Content: styled.div`
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 410px;
    padding: 80px 0 0 0;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding: 30px 0 0 0;
    }
  `,
  Title: styled(HolidayStyles.GradientText)`
    font-family: ${FONTS.LORA};
    font-size: 90px;
    line-height: 1;
    letter-spacing: 1.8px;
    font-weight: 400;
    background: linear-gradient(
      135deg,
      ${COLORS.CLAY_BROWN},
      ${COLORS.SANDY_BROWN}
    );
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    max-width: 400px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      font-size: 65px;
      letter-spacing: 1.5px;
      max-width: 320px;
    }
  `,
  Divider: styled(Divider)`
    width: 130px;
    margin-top: 15px;
    margin-bottom: 20px;
  `,
  Subtitle: styled(HolidayStyles.GradientText)`
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: 0.36px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      max-width: 220px;
    }
  `,
}
