import Img from "gatsby-image"
import styled, { css, FlattenSimpleInterpolation } from "styled-components"
import { COLORS, IPHONE5_MAX_WIDTH } from "../../../styles"
import { T1, T2 } from "../../common/text"
import HolidayStyles from "../page.styles"

type position = "left" | "right" | "mobile"

const getPositon = (postion: position): FlattenSimpleInterpolation => {
  switch (postion) {
    case "left":
      return css`
        top: 110px;
        left: 150px;
      `
    case "right":
      return css`
        top: 110px;
        right: 150px;
      `
    case "mobile":
      return css`
        top: 30px;
        right: 25px;
        left: 25px;
      `
    default:
      return css``
  }
}

export default {
  Banner: styled.div<{ backgroundColor: string; position: position }>`
    position: relative;
    max-width: 1576px;
    display: flex;
    justify-content: center;
    height: ${props => (props.position === "mobile" ? "560px" : "400px")};
    background-color: ${props => props.backgroundColor};
    margin: 0px auto;
    margin-top: 10px;
  `,
  Image: styled(Img)<{ position: position }>`
    position: absolute;
    width: ${props => (props.position === "mobile" ? "100%" : "1576px")};
    height: ${props => (props.position === "mobile" ? "560px" : "400px")};
    @media screen and (max-width: ${IPHONE5_MAX_WIDTH}px) {
      width: 320px;
    }
  `,
  Content: styled.div<{ position: position }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 326px;
    text-align: center;
    margin: 0px auto;
    ${props => getPositon(props.position)}
  `,
  Title: styled(HolidayStyles.GradientText)`
    font-size: 40px;
    line-height: 1;
    letter-spacing: 0.24px;
    margin-bottom: 10px;
    text-transform: uppercase;
  `,
  SubTitle: styled(T1)`
    margin-bottom: 25px;
  `,
  CTA: styled(T2)`
    padding: 12px 45px;
    text-transform: uppercase;
    height: 40px;
    background: linear-gradient(
      to right,
      ${COLORS.CLAY_BROWN},
      ${COLORS.SANDY_BROWN}
    );
    cursor: pointer;
    text-decoration: none;
  `,
}
