import Slider from "react-slick"
import styled from "styled-components"
import { COLORS } from "../../../styles"
import { H4 } from "../../common/text"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default {
  Container: styled.div`
    padding: 30px 0px 20px 0px;
    width: 100%;
    text-align: center;
  `,
  Title: styled(H4)`
    line-height: 1;
    letter-spacing: normal;
    margin-bottom: 30px;
  `,
  Card: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Slider: styled(Slider)`
    .slick-list {
      height: 410px;
    }
    .slick-track {
      height: 410px;
    }
    .slick-dots {
      position: static;
      margin-top: 20px;
    }
    .slick-dots li {
      border: 1px solid ${COLORS.WHITE};
      border-radius: 50%;
      width: 10px;
      height: 10px;
    }
    .slick-dots li.slick-active {
      background-color: ${COLORS.WHITE};
    }
    .slick-dots li button {
      display: none;
    }
  `,
}
