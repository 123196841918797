import React, { FunctionComponent } from "react"
import SpecialPageCard from "../card"
import { SpecialPageItem } from "../card/card"
import Styles from "./carousel.styles"

interface Props {
  title: string
  items: SpecialPageItem[]
}

const settings = {
  centerMode: true,
  dots: true,
  infinite: true,
  variableWidth: true,
  arrows: false,
}

const SpecialPageCarousel: FunctionComponent<Props> = ({ title, items }) => (
  <Styles.Container>
    <Styles.Title $textColor="white">{title}</Styles.Title>
    {items.length > 1 ? (
      <Styles.Slider {...settings}>
        {items.map(item => (
          <SpecialPageCard key={item.id} item={item} />
        ))}
      </Styles.Slider>
    ) : (
      <Styles.Card>
        <SpecialPageCard key={items[0].id} item={items[0]} />
      </Styles.Card>
    )}
  </Styles.Container>
)

export default SpecialPageCarousel
