import { FluidObject } from "gatsby-image"
import React, { FunctionComponent } from "react"
import { S1 } from "../../common/text"
import Styles from "./card.styles"

export type HolidayCategory = {
  cardBackgroundColor: string
  flakeBackgroundColor: string
  flakeColor: string
  name: string
  textColor: "white" | "green"
}

export type SpecialPageItem = {
  title: string
  id: string
  link: string
  subtitle: string
  category: HolidayCategory
  cta: string
  fileAttachment?: {
    file: {
      url: string
    }
  }
  thumbnail: { fluid: FluidObject }
  order: number | null
}

interface Props {
  item: SpecialPageItem
}

const SpecialPageCard: FunctionComponent<Props> = ({ item }) => (
  <Styles.Container $backgroundColor={item.category.cardBackgroundColor}>
    <Styles.Image fluid={item.thumbnail.fluid}></Styles.Image>
    <Styles.ContentContainer>
      <Styles.Flake
        $flakeColor={item.category.flakeColor}
        $backgroundColor={item.category.flakeBackgroundColor}
      />
      <S1 $textColor={item.category.textColor}>{item.subtitle}</S1>
      <Styles.Title $textColor={item.category.textColor}>
        {item.title}
      </Styles.Title>
      {item.link || item.fileAttachment ? (
        <Styles.GetMoreInfo
          to={item.fileAttachment?.file.url || item.link}
          color={item.category.textColor}
        >
          {item.cta || "Get More Info"}
        </Styles.GetMoreInfo>
      ) : (
        <Styles.NonLinkCTA $textColor={item.category.textColor}>
          {item.cta || "Get More Info"}
        </Styles.NonLinkCTA>
      )}
    </Styles.ContentContainer>
  </Styles.Container>
)

export default SpecialPageCard
