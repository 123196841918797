import { graphql, PageProps } from "gatsby"
import { FluidObject } from "gatsby-image"
import { isNull, uniq } from "lodash"
import React, { FunctionComponent } from "react"
import SEO from "../../components/common/seo"
import SpecialPageBanner, {
  Banner,
} from "../../components/special-page/banner/banner"
import SpecialPageCard, {
  SpecialPageItem,
} from "../../components/special-page/card/card"
import SpecialPageCarousel from "../../components/special-page/carousel"
import CategoryButton from "../../components/special-page/categoryButton"
import SpecialPageHeader from "../../components/special-page/header"
import Style from "../../components/special-page/page.styles"
import Layout from "../../components/layout/layout"

interface Data {
  allContentfulHolidaysItems: {
    edges: {
      node: SpecialPageItem
    }[]
  }
  contentfulSpecialPage: {
    seoTitle: string
    seoDescription: string
    headerVideo: {
      file: {
        url: string
      }
    }
    headerMobileImage: {
      fluid: FluidObject
    }
    banners: Banner[]
    headerTitle: string
    headerSubtitle: string
    items: SpecialPageItem[]
  }
}

const SpecialPage: FunctionComponent<PageProps<Data>> = ({ data }) => {
  const {
    seoTitle,
    seoDescription,
    banners,

    headerVideo,
    headerSubtitle,
    headerTitle,
    items,
  } = data.contentfulSpecialPage

  const [selectedCategory, setSelectedCategory] = React.useState<string | null>(
    null
  )

  const categoryNames = uniq(items.map(edge => edge.category.name))
  const filteredItems = selectedCategory
    ? items
        .map(edge => edge)
        .filter(item => item.category.name === selectedCategory)
    : items.map(edge => edge)

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <Style.Layout greaterThanOrEqual="sm">
        {headerVideo && (
          <SpecialPageHeader
            media={headerVideo.file.url}
            subtitle={headerSubtitle}
            title={headerTitle}
          />
        )}
        <Style.CategoryRow>
          <CategoryButton
            isActive={isNull(selectedCategory)}
            onClick={() => setSelectedCategory(null)}
          >
            All
          </CategoryButton>
          {categoryNames.map(name => (
            <CategoryButton
              key={name}
              isActive={selectedCategory === name}
              onClick={() => {
                setSelectedCategory(name)
              }}
            >
              {name}
            </CategoryButton>
          ))}
        </Style.CategoryRow>
        <Style.Cards>
          {filteredItems.map(item => (
            <SpecialPageCard key={item.id} item={item} />
          ))}
        </Style.Cards>
      </Style.Layout>
      <Style.Layout lessThan="sm">
        {categoryNames.map(name => {
          const carouselItems = items.filter(
            item => item.category.name === name
          )
          if (carouselItems)
            return (
              <SpecialPageCarousel
                key={name}
                title={name}
                items={carouselItems}
              />
            )
        })}
      </Style.Layout>
      <Style.Banners>
        {banners &&
          banners.map((banner, index) => (
            <SpecialPageBanner
              key={banner.id}
              item={banner}
              position={index % 2 == 0 ? "left" : "right"}
            />
          ))}
      </Style.Banners>
    </Layout>
  )
}

// We don't export the query since the page is disabled
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const query = graphql`
  query($slug: String!) {
    contentfulSpecialPage(slug: { eq: $slug }) {
      seoTitle
      seoDescription
      banners {
        id
        cta
        link
        image {
          fluid(maxWidth: 1576, quality: 90) {
            ...GatsbyContentfulFluid
          }
        }
      }
      headerSubtitle
      headerTitle
      headerVideo {
        file {
          url
        }
      }
      items {
        title
        id
        link
        subtitle
        category {
          cardBackgroundColor
          flakeBackgroundColor
          flakeColor
          name
          textColor
        }
        cta
        fileAttachment {
          file {
            url
          }
        }
        order
        thumbnail {
          fluid(quality: 70) {
            ...GatsbyContentfulFluid
          }
        }
      }
      headerMobileImage {
        fluid(maxWidth: 700, quality: 90) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`

export default SpecialPage
