import styled from "styled-components"
import { Media } from "../../Media"
import { COLORS, FONTS, SM_BREAKPOINT } from "../../styles"

export default {
  Layout: styled(Media)`
    width: 100%;
    background-color: ${COLORS.HOLLY_GREEN};
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      display: block;
    }
  `,
  Cards: styled.div`
    padding: 50px 0px 40px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: fit-content;
    column-gap: 12px;
    row-gap: 12px;
    @media screen and (max-width: 1620px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  `,
  Banners: styled.div`
    overflow-x: hidden;
    background-color: ${COLORS.HOLLY_GREEN};
    width: 100%;
  `,
  CategoryRow: styled.div`
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: ${COLORS.BOTTLE_GREEN};
    height: 60px;
  `,
  GradientText: styled.div`
    font-family: ${FONTS.LORA};
    font-weight: 400;
    background: linear-gradient(
      135deg,
      ${COLORS.CLAY_BROWN},
      ${COLORS.SANDY_BROWN}
    );
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  `,
}
