import { Link } from "gatsby"
import { FluidObject } from "gatsby-image"
import React, { FunctionComponent } from "react"
import { Media } from "../../../Media"
import Styles from "./banner.styles"

export type Banner = {
  id: string
  cta: string
  image: {
    fluid: FluidObject
  }
  mobileImage: {
    fluid: FluidObject
  }
  subtitle: string
  title: string
  link: string
  backgroundColor: string
  fileAttachment?: {
    file: {
      url: string
    }
  }
}

interface Props {
  item: Banner
  position: "left" | "right" | "mobile"
}
const Banner: FunctionComponent<Props> = ({ item, position }) => (
  <Styles.Banner backgroundColor={item.backgroundColor} position={position}>
    <Styles.Image
      position={position}
      fluid={
        position === "mobile" && item.mobileImage
          ? item.mobileImage.fluid
          : item.image.fluid
      }
    />
    <Styles.Content position={position}>
      <Styles.Title>{item.title}</Styles.Title>
      <Styles.SubTitle $textColor="white">{item.subtitle}</Styles.SubTitle>
      {item.fileAttachment ? (
        <Styles.CTA
          as="a"
          $textColor="white"
          href={item.fileAttachment.file.url}
          target="_blank"
        >
          {item.cta}
        </Styles.CTA>
      ) : (
        <Styles.CTA as={Link} $textColor="white" to={item.link}>
          {item.cta}
        </Styles.CTA>
      )}
    </Styles.Content>
  </Styles.Banner>
)

const SpecialPageBanner: FunctionComponent<Props> = ({ item, position }) => (
  <>
    <Media lessThan="sm">
      <Banner item={item} position="mobile" />
    </Media>
    <Media greaterThanOrEqual="sm">
      <Banner item={item} position={position} />
    </Media>
  </>
)

export default SpecialPageBanner
