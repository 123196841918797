import { FluidObject } from "gatsby-image"
import React, { FunctionComponent } from "react"
import Styles from "./header.styles"

interface Props {
  media: string | { fluid: FluidObject }
  title: string
  subtitle: string
}

const isVideo = (media: string | { fluid: FluidObject }): media is string =>
  !(media as { fluid: FluidObject }).fluid

const SpecialPageHeader: FunctionComponent<Props> = ({
  media,
  subtitle,
  title,
}) => (
  <Styles.Container>
    {media &&
      (isVideo(media) ? (
        <Styles.Video
          width="100%"
          height="100%"
          loop={true}
          playing={true}
          url={media}
          config={{
            file: {
              attributes: {
                playsInline: true,
                muted: true,
              },
            },
          }}
        />
      ) : (
        <Styles.ImageWrapper>
          <Styles.Image fluid={media.fluid} />
        </Styles.ImageWrapper>
      ))}
    <Styles.Content>
      <Styles.Title>{title}</Styles.Title>
      <Styles.Divider />
      <Styles.Subtitle>{subtitle}</Styles.Subtitle>
    </Styles.Content>
  </Styles.Container>
)

export default SpecialPageHeader
