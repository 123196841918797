import Img from "gatsby-image"
import styled from "styled-components"
import Snowflake from "../../../images/snowflake.svg"
import { FONTS, LG_BREAKPOINT, SM_BREAKPOINT } from "../../../styles"
import { CTA } from "../../common/cta"
import { BaseText, H5 } from "../../common/text"

export default {
  Container: styled.div<{ $backgroundColor: string }>`
    display: flex;
    flex-direction: column;
    width: 385px;
    height: 440px;
    background-color: ${props => props.$backgroundColor};
    @media screen and (max-width: ${LG_BREAKPOINT}px) {
      width: 315px;
      height: 410px;
    }
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      /* We need margin to space in the carousel */
      margin: 0 6px;
    }
  `,
  Image: styled(Img)`
    width: 100%;
    height: 235px;
    @media screen and (max-width: ${LG_BREAKPOINT}px) {
      height: 215px;
    }
  `,
  Flake: styled(Snowflake)<{ $flakeColor: string; $backgroundColor: string }>`
    .circle {
      fill: ${props => props.$backgroundColor};
    }
    .flake {
      stroke: ${props => props.$flakeColor};
    }
    height: 50px;
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  `,
  ContentContainer: styled.div`
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 45px 60px 30px 60px;
    @media screen and (max-width: ${LG_BREAKPOINT}px) {
      padding: 45px 30px 30px 30px;
    }
  `,
  Title: styled(H5)`
    text-align: center;
  `,
  GetMoreInfo: styled(CTA)`
    margin-top: auto;
  `,
  NonLinkCTA: styled(BaseText)`
    font-family: ${FONTS.SOHNE_FONT_FAMILY};
    font-weight: 400;
    font-size: 13px;
    line-height: 1.31;
    letter-spacing: 0.39px;
    margin-top: auto;
  `,
}
