import styled, { css } from "styled-components"
import { COLORS, LG_BREAKPOINT, MD_BREAKPOINT } from "../../styles"
import { T1 } from "../common/text"

export default {
  CategoryButton: styled(T1)<{ isActive?: boolean }>`
    height: 100%;
    min-width: 45px;
    padding: 5px 0 0 0;
    margin: 0px 50px;
    background-color: transparent;
    border: none;
    border-bottom: 5px solid transparent;
    ${props =>
      props.isActive
        ? css`
            border-bottom-color: ${COLORS.CLAY_BROWN};
          `
        : ""};
    :focus {
      color: ${COLORS.CLAY_BROWN};
      border-bottom-color: ${COLORS.CLAY_BROWN};
      outline: none;
    }
    :hover {
      color: ${COLORS.CLAY_BROWN};
    }
    @media screen and (max-width: ${LG_BREAKPOINT}px) {
      margin: 0 30px;
    }
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      /* padding: 10px 0 5px 0; */
    }
  `,
}
